import { useMemo } from 'react'
import { useMutation, useQueries, useQuery, useQueryClient, UseQueryOptions } from 'react-query'
import { useAserto } from '@aserto/aserto-react'
import { useShowError } from '@aserto/console-common'

import { useAccountClient } from '../clients/AccountClient'
import { useHubSpotClient } from '../clients/HubSpotClient'
import { useTenantClient } from '../clients/TenantClient'
import {
  MutationKeys,
  QueryKeys,
  reactQueryConnectionCacheOptions,
  reactQueryDefaultOptions,
  ReactQueryMutationHooks,
  useMutationOptions,
} from '../types/local/general'
import { ProviderId, ProviderName } from '../types/local/hardcodedBackendConstants'
import {
  Connection,
  ConnectionAvailableResponse,
  CreateConnectionRequestBody,
  CreateConnectionResponse,
  DeleteConnectionResponse,
  GetConnectionResponse,
  ListConnectionsResponse,
  ListProvidersResponse,
  ProviderKind,
  RotateSecretRequestBody,
  RotateSecretResponse,
  RpcStatus,
  UpdateConnectionRequestBody,
  UpdateConnectionResponse,
} from '../types/local/tenant'

export const useCanGetConnection = (): boolean => {
  const { getDisplayState } = useAserto()
  try {
    return getDisplayState('aserto/tenant/connection/v1/Connection/GetConnection')?.enabled
  } catch {
    return false
  }
}

export const useConnections = ({ kind }: { kind?: ProviderKind } = {}, enabled?: boolean) => {
  const { get } = useTenantClient()
  return useQuery(
    [QueryKeys.ConnectionsData.toString()].concat(!!kind ? [kind] : []),
    () =>
      get<ListConnectionsResponse>({
        path: 'connections',
        queryParams: !!kind ? { kind: kind } : undefined,
      }),
    {
      ...reactQueryDefaultOptions,
      enabled: enabled,
    }
  )
}

export const useConnection = (connectionId: string | undefined, enabled?: boolean) => {
  return useConnectionByPredicate((c) => c.id === connectionId, enabled)
}

const useConnectionWrapper = (connectionId: string | undefined, enabled?: boolean) => {
  const { get } = useTenantClient()
  const queryClient = useQueryClient()
  const showError = useShowError()

  return useQuery(
    [QueryKeys.ConnectionsData, connectionId],
    () => get<GetConnectionResponse>({ path: `connections/${connectionId}` }),
    {
      ...reactQueryConnectionCacheOptions,
      enabled: !!connectionId && enabled,
      onSuccess: (d) => {
        const replacementItem = d.result
        const connections = (
          queryClient.getQueryData(QueryKeys.ConnectionsData) as ListConnectionsResponse
        )?.results

        if (!!replacementItem && !!connections) {
          const index = connections.findIndex((c) => c.id === replacementItem?.id)
          if (connections[index].last_verification_at !== replacementItem.last_verification_at) {
            Object.assign(connections[index], replacementItem)
            queryClient.setQueryData(QueryKeys.ConnectionsData, {
              results: connections,
            })
          }
        }
      },
      onError: (d: RpcStatus) => {
        // check for a StatusForbidden (403) error, which indicates the JWT does not have access to the tenant
        if (d.code === 7 && d.message?.startsWith('E10032')) {
          queryClient.clear()
          showError(d)
        }
      },
    }
  )
}

export const useGetConnections = (connectionIds: string[]) => {
  const { get } = useTenantClient()

  return useQueries(
    connectionIds.map((connectionId) => {
      return {
        queryKey: [QueryKeys.ConnectionsData, connectionId],
        queryFn: async () =>
          await get<GetConnectionResponse>({ path: `connections/${connectionId}` }),
      }
    })
  )
}

export const useAuthorizerConnection = () => {
  return useConnectionByPredicate((c) => c.kind === 'PROVIDER_KIND_AUTHORIZER')
}

export const useApcrConnection = () => {
  return useConnectionByPredicate(
    (c) => c.kind === 'PROVIDER_KIND_POLICY_REGISTRY' && c.provider_id === ProviderId.AsertoRegistry
  )
}

export const useAppiConnection = () => {
  return useConnectionByPredicate(
    (c) =>
      c.kind === 'PROVIDER_KIND_POLICY_REGISTRY' &&
      c.provider_id === ProviderId.AsertoPublicPolicyImages
  )
}

export const useCentralDirectoryConnection = (enabled?: boolean) => {
  return useConnectionByPredicate(
    (c) => c.kind === 'PROVIDER_KIND_DIRECTORY' && c.provider_id === ProviderId.CentralDirectory,
    enabled
  )
}

const useConnectionByPredicate = (predicate: (c: Connection) => boolean, enabled?: boolean) => {
  const { data: connectionsData, ...all } = useConnections({}, enabled)
  const canGetConnection = useCanGetConnection()

  const connectionFromList = useMemo(() => {
    if (connectionsData === undefined) {
      return undefined
    }
    const connection = connectionsData.results!.find(predicate)
    return connection
  }, [connectionsData, predicate])

  const fullConnection = useConnectionWrapper(connectionFromList?.id, canGetConnection)

  return canGetConnection ? fullConnection : { data: { result: connectionFromList }, ...all }
}

export const useProviders = (kind?: ProviderKind) => {
  const { get } = useAccountClient()
  return useQuery(
    [QueryKeys.ProvidersData, ...(!!kind ? [kind] : [])],
    () => get<ListProvidersResponse>({ path: 'providers' + (!!kind ? `?kind=${kind}` : '') }),
    reactQueryDefaultOptions
  )
}

export const useSCCProviders = () => {
  return useProviders('PROVIDER_KIND_SCC')
}

export const useIsConnectionNameAvailable = (
  connectionName: string,
  options?: Omit<
    UseQueryOptions<
      ConnectionAvailableResponse,
      RpcStatus,
      ConnectionAvailableResponse,
      QueryKeys[]
    >,
    'queryKey' | 'queryFn' | 'retry' | 'staleTime'
  >
) => {
  const { get } = useTenantClient()
  return useQuery(
    [QueryKeys.IsConnectionNameAvailable, connectionName],
    () => {
      return get<ConnectionAvailableResponse>({
        path: `connections/available/${connectionName}`,
      })
    },

    {
      ...reactQueryDefaultOptions,
      enabled: !!connectionName && options?.enabled,
    }
  )
}

export const useDeleteConnection = (
  mutationHooks: ReactQueryMutationHooks<DeleteConnectionResponse, { connectionId: string }> = {}
) => {
  const queryClient = useQueryClient()
  const mutationOptions = useMutationOptions(
    mutationHooks,
    [QueryKeys.ConnectionsData],
    [QueryKeys.IsConnectionNameAvailable]
  )
  const { del } = useTenantClient()

  return useMutation(
    [MutationKeys.DeleteConnection],
    ({ connectionId }) => del({ path: `connections/${connectionId}` }),
    {
      ...mutationOptions,
      async onSuccess(data, variables, context) {
        queryClient.removeQueries([QueryKeys.ProvidersData])
        queryClient.removeQueries([QueryKeys.ConnectionsData, variables.connectionId])
        queryClient.removeQueries([QueryKeys.IsConnectionNameAvailable])
        mutationOptions?.onSuccess?.(data, variables, context)
      },
    }
  )
}

export const useAddConnection = (
  reactQueryHooks: ReactQueryMutationHooks<
    CreateConnectionResponse,
    CreateConnectionRequestBody
  > = {}
) => {
  const { hubspotSetProperty } = useHubSpotClient()
  const mutationOptions = useMutationOptions(
    {
      ...reactQueryHooks,
      onSuccess: async (result, { provider_id, ...args }, context) => {
        const providerName = ProviderName[provider_id as keyof typeof ProviderName]
        if (providerName) {
          const name = `connected_${providerName}`
          try {
            await hubspotSetProperty({
              name,
              value: '1',
            })
          } catch (e) {}
        }
        reactQueryHooks.onSuccess?.(result, { provider_id, ...args }, context)
      },
    },
    [QueryKeys.ConnectionsData],
    [QueryKeys.IsConnectionNameAvailable]
  )

  const { post } = useTenantClient()

  return useMutation(
    [MutationKeys.CreateConnection],
    ({ config, name, description, kind, provider_id }) =>
      post({
        path: 'connections',
        body: {
          config,
          name,
          description,
          kind,
          provider_id,
        },
      }),
    mutationOptions
  )
}

export const useUpdateConnection = (
  reactQueryHooks: ReactQueryMutationHooks<
    UpdateConnectionResponse,
    { connectionId: string } & UpdateConnectionRequestBody
  > = {}
) => {
  const mutationOptions = useMutationOptions(
    reactQueryHooks,
    (response) => [QueryKeys.ConnectionsData, response.id],
    [QueryKeys.ConnectionsData],
    [QueryKeys.IsConnectionNameAvailable]
  )
  const { put } = useTenantClient()
  const queryClient = useQueryClient()

  return useMutation(
    [MutationKeys.UpdateConnection],
    ({ connectionId, ...body }) => put({ body, path: `connections/${connectionId}` }),
    {
      ...mutationOptions,
      onSuccess: (data, context, variables) => {
        queryClient.removeQueries([QueryKeys.ConnectionsData, context.connectionId])
        queryClient.removeQueries([QueryKeys.IsConnectionNameAvailable])
        mutationOptions?.onSuccess?.(data, context, variables)
      },
    }
  )
}

export const useRotateKey = (
  mutationHooks: ReactQueryMutationHooks<
    RotateSecretResponse,
    { id: string; secretKey: string } & RotateSecretRequestBody
  > = {}
) => {
  const mutationOptions = useMutationOptions(mutationHooks, (response) => [
    QueryKeys.ConnectionsData,
    response.result!.id,
  ])
  const { put } = useTenantClient()

  return useMutation(
    [MutationKeys.RotateKey],
    ({ id, secretKey, ...body }) => put({ body, path: `connections/${id}/${secretKey}/rotate` }),
    mutationOptions
  )
}
